import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import headshot from "../assets/headshot1.jpg";

const AboutMe = () => {
  const { theme } = useContext(ThemeContext);

  var TEXT_THEME = theme === "dark-theme" ? "segoe white" : "segoe black";
  const BACKGROUND_CLASS = `page bg-animation-${theme}-2`;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "fit-content",
      }}
      id="aboutme"
    >
      <div
        id="top"
        className={BACKGROUND_CLASS}
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "100%",

            position: "relative",
            display: "flex",
            flexWrap: "wrap",

            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              maxWidth: "80%",
              minHeight: "100vh",
              display: "flex",
              flexWrap: "wrap",
              paddingTop: 30,
              paddingBottom: 30,
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                minWidth: 300,
              }}
            >
              <img
                src={headshot}
                style={{
                  borderRadius: "50% 50% 0px 50%",
                  width: "100%",
                  padding: 20,
                  paddingBottom: 0,
                }}
                alt="DisplayPhoto"
              ></img>
            </div>
            <div
              style={{
                width: "60%",
                minWidth: 300,
                padding: 20,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <h1>
                <span
                  className={TEXT_THEME}
                  style={{
                    fontSize: 70,
                    flexShrink: true,
                  }}
                >
                  ABOUT ME
                </span>
              </h1>
              <p>
                <span
                  className={TEXT_THEME}
                  style={{
                    flexShrink: true,
                    //backgroundColor: theme === "dark-theme" ? "white" : "black",
                    padding: 2,
                    margin: 0,
                  }}
                >
                  I am a full-stack developer based in Adelaide, South
                  Australia, with a passion for building scalable and high
                  performing applications. My expertise spans both front-end and
                  back-end development, with a strong foundation in designing
                  and implementing microservices and event-driven architectures
                  using Java Spring and Apache Kafka. <br />
                  <br />
                  With hands-on experience in building web applications, I have
                  worked extensively with JavaScript (Next.js React, React
                  Native, Node.js) and Java (Spring Boot), as well as both
                  relational and non-relational databases.
                  <br />
                  <br /> Beyond my technical capabilities, I have a background
                  in interaction design, graphic design, and UI/UX, allowing me
                  to develop applications that prioritize both functionality and
                  user experience. I am always eager to expand my skill set,
                  explore emerging technologies, and build systems that are both
                  efficient and impactful
                </span>
              </p>
            </div>
          </div>
          <button
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "#00000000",
              border: "none",
              marginRight: "40px",
              marginBottom: "40px",
            }}
          >
            <a href="#top">
              <div className={"up-arrow " + theme}></div>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
